@font-face {
	font-family: Montserrat-Black; src: local("Montserrat Black"), local("Montserrat-Black"), url(./font/Montserrat/Montserrat-Black.ttf);
}
@font-face {
	font-family: Montserrat-BlackItalic; src: local("Montserrat Black Italic"), local("Montserrat-BlackItalic"), url(./font/Montserrat/Montserrat-BlackItalic.ttf);
}
@font-face {
	font-family: Montserrat-Bold; src: local("Montserrat Bold"), local("Montserrat-Bold"), url(./font/Montserrat/Montserrat-Bold.ttf);
}
@font-face {
	font-family: Montserrat-BoldItalic; src: local("Montserrat Bold Italic"), local("Montserrat-BoldItalic"), url(./font/Montserrat/Montserrat-BoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-ExtraBold; src: local("Montserrat ExtraBold"), local("Montserrat-ExtraBold"), url(./font/Montserrat/Montserrat-ExtraBold.ttf);
}
@font-face {
	font-family: Montserrat-ExtraBold; src: local("Montserrat ExtraBold Italic"), local("Montserrat-ExtraBoldItalic"), url(./font/Montserrat/Montserrat-ExtraBoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-ExtraLight; src: local("Montserrat ExtraLight"), local("Montserrat-ExtraLight"), url(./font/Montserrat/Montserrat-ExtraLight.ttf);
}
@font-face {
	font-family: Montserrat-ExtraLight; src: local("Montserrat ExtraLight Italic"), local("Montserrat-ExtraLightItalic"), url(./font/Montserrat/Montserrat-ExtraLightItalic.ttf);
}
@font-face {
	font-family: Montserrat-Italic; src: local("Montserrat Italic"), local("Montserrat-Italic"), url(./font/Montserrat/Montserrat-Italic.ttf);
}
@font-face {
	font-family: Montserrat-Light; src: local("Montserrat Light"), local("Montserrat-Light"), url(./font/Montserrat/Montserrat-Light.ttf);
}
@font-face {
	font-family: Montserrat-LightItalic; src: local("Montserrat Light Italic"), local("Montserrat-LightItalic"), url(./font/Montserrat/Montserrat-LightItalic.ttf);
}
@font-face {
	font-family: Montserrat-Medium; src: local("Montserrat Medium"), local("Montserrat-Medium"), url(./font/Montserrat/Montserrat-Medium.ttf);
}
@font-face {
	font-family: Montserrat-MediumItalic; src: local("Montserrat Medium Italic"), local("Montserrat-MediumItalic"), url(./font/Montserrat/Montserrat-MediumItalic.ttf);
}
@font-face {
	font-family: Montserrat-Regular; src: local("Montserrat Regular"), local("Montserrat-Regular"), url(./font/Montserrat/Montserrat-Regular.ttf);
}
@font-face {
	font-family: Montserrat-SemiBold; src: local("Montserrat SemiBold"), local("Montserrat-SemiBold"), url(./font/Montserrat/Montserrat-SemiBold.ttf);
}
@font-face {
	font-family: Montserrat-SemiBoldItalic; src: local("Montserrat SemiBold Italic"), local("Montserrat-SemiBoldItalic"), url(./font/Montserrat/Montserrat-SemiBoldItalic.ttf);
}
@font-face {
	font-family: Montserrat-Thin; src: local("Montserrat Thin"), local("Montserrat-Thin"), url(./font/Montserrat/Montserrat-Thin.ttf);
}
@font-face {
	font-family: Montserrat-ThinItalic; src: local("Montserrat Thin Italic"), local("Montserrat-ThinItalic"), url(./font/Montserrat/Montserrat-ThinItalic.ttf);
}

:root {
	--blueSkyTill: #00A8E8;
	--lightBlueSkyTill: #BCE3FA;
	--lightBlueAlphaSkyTill: rgba(188, 227, 250, 0.3);
	--darkBlueSkyTill: #00406F;
	--darkGraySkyTill: #9C9C9C;
	--darkerGraySkyTill: #646464;
	--lightGraySkyTill: #DADADA;
	--lighterGraySkyTill: #F5F5F5;
	--whiteSkyTill: #FFFFFF;
	--whiteHoverSkyTill: rgba(255, 255, 255, 0.1);
	--blackSkyTill: #000000;
	--redSkyTill: #EB4343;
	--redHoverSkyTill: #EB6D6D;
	--redAlphaSkyTill: rgba(235, 67, 67, 0.1);
	--orangeSkyTill: #C38613;
	--orangeHoverSkyTill: #C59B4C;
	
    --size-head: 50px;
}

html, body {
	height: 100%;
	margin: 0;
	padding: 0;
	font-family: Montserrat-Regular, Arial, sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.wrapper {
	display: flex;
	flex-flow: row;
	overflow-y: auto;
	overflow-x: hidden;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100%;
	margin: 0;
	padding: 0;
	background-color: rgba(245,245,245,1);
}
.wrapper > .wrap {
	display: block;
	overflow-y: auto;
	overflow-x: hidden;
    width: 100%;
    height: calc(100vh - var(--size-head));
    min-height: calc(100vh - var(--size-head));
    max-height: calc(100vh - var(--size-head));
	margin: var(--size-head) 0 0 0;
	padding: 0;
}

.head {
    position: fixed;
    width: 100%;
    height: var(--size-head);
    top: 0;
    left: 0;
    background-color: var(--lighterGraySkyTill);
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.3);
}

.head > .filter {
	float: left;
	width: 30%;
	height: var(--size-head);
	margin: 0;
	padding: 0;
}
.head > .filter > .inputText {
	width: 100%;
	height: calc(var(--size-head) - 20px);
	margin: 10px 0 0 10px;
	padding: 0 15px;
	border-radius: 3px;
	background-color: var(--lightGraySkyTill);
	font-size: 11pt;
	text-transform: uppercase;
	border: none;
	cursor: pointer;
	transition: all .15s;
}
.head > .filter > .inputText.on {
	background-color: var(--darkBlueSkyTill);
	color: var(--whiteSkyTill);
}
.head > .filter > .inputText:focus {
	outline: none;
}

.head > .menu {
    float: right;
    width: auto;
    height: var(--size-head);
    margin: 0;
    padding: 0;
}
.head > .menu > .item {
    float: left;
    height: calc(100% - 20px);
    margin: 10px 10px 0 0;
    padding: 0 15px;
    border-radius: 3px;
    background-color: var(--lightGraySkyTill);
    font-size: 10pt;
    line-height: calc(var(--size-head) - 20px);
    cursor: pointer;
    transition: all .15s;
}
.head > .menu > .item.error {
    background-color: var(--redAlphaSkyTill);
}
.head > .menu > .item:not(.error).selected {
    background-color: var(--darkBlueSkyTill);
	color: var(--whiteSkyTill);
}
.head > .menu > .item.error.selected {
	background-color: var(--redSkyTill);
	color: var(--whiteSkyTill);
}
.head > .menu > .item:not(.selected):not(.error):hover {
    background-color: var(--darkGraySkyTill);
}
.head > .menu > .item:not(.selected).error:hover {
    background-color: var(--redHoverSkyTill);
}

.head > .keys {
	display: block;
	position: fixed;
    top: 65px;
	right: 15px;
    margin: 0;
    padding: 0 25px;
    font-size: 22pt;
	font-family: Montserrat-SemiBold, Arial, Montserrat-SemiBold, Arial, sans-serif;
    line-height: var(--size-head);
	background-color: var(--lightGraySkyTill);
	border-radius: 30px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
.head > .keys > span {
	padding: 0 0 0 10px;
	font-size: 11pt;
	color: var(--darkerGraySkyTill);
}

.list {
	display: table;
	width: 100%;
	border-collapse: collapse;
	margin: 0;
	padding: 0;
}
.list tr {
	width: 100%;
	height: 40px;
	padding: 0;
	cursor: pointer;
	transition: all 0.15s;
}
.list tr:hover {
	background-color: rgba(235, 235, 235, 1);
}
.list tr td {
	padding: 0 10px;
	font-size: 9pt;
}
.list tr td.left {
	text-align: left;
}
.list tr td.right {
	text-align: right;
}
.list tr td.center {
	text-align: center;
}
.list tr td.client {
	width: 70%;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.list tr td.date {
	width: 150px;
	min-width: 150px;
	max-width: 150px;
}
.list tr td.status {
	width: 40px;
	min-width: 40px;
	max-width: 40px;
	display: block;
	margin: 0;
	color: white;
	line-height: 40px;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
}
.list tr td.status.ok {
	background-color: rgba(1,157,54,0.98);
}
.list tr td.status.nok {
	background-color: rgb(181, 63, 63);
}
.list tr td.method {
	width: 60px;
	min-width: 60px;
	max-width: 60px;
}
.list tr td.action {
	width: 30%;
}
.list tr td.runtime {
	width: 80px;
	min-width: 80px;
	max-width: 80px;
}
.list tr td.ip {
	width: 100px;
	min-width: 100px;
	max-width: 100px;
	padding-right: 20px;
}

.overlayer {
	position: fixed;
	z-index: 100;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	background-color: rgba(0, 0, 0, 0.6);
	transition: all 0.15s;
	animation: opacityUp 0.3s ease;
}

.wrapOverbox {
	display: flex;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: 100vh;
	max-height: 100%;
	margin: 0;
	padding: 0;
}

.overbox {
	z-index: 101;
	position: fixed;
	height: auto;
	margin: 0;
	padding: 0;
	background-color: #FFFFFF;
	box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
	transition: all 0.15s;
	animation: boxUp 0.3s ease;
}
.overbox.preview {
	width: calc(100% - 100px);
	max-width: calc(100% - 100px);
	height: calc(100% - 100px);
	max-height: calc(100% - 100px);
}

.overbox.preview > .wrap {
	overflow-x: hidden;
	overflow-y: auto;
	max-height: 100%;
}

.overbox.preview > .wrap > .code {
	display: block;
	position: absolute;
	top: 15px;
	right: 15px;
	margin: 0;
	padding: 0 20px;
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	font-size: 14pt;
	line-height: 46px;
	color: #FFFFFF;
	border-radius: 3px;
}
.overbox.preview > .wrap > .code.ok {
	background-color: rgba(1,157,54,0.98);
}
.overbox.preview > .wrap > .code.nok {
	background-color: rgb(181, 63, 63);
}

.overbox.preview > .wrap > ul {
	margin: 25px 15px 15px 15px;
	padding: 0;
	list-style-type: none;
}
.overbox.preview > .wrap > ul > li {
	padding: 10px 0 0 0;
	font-size: 10pt;
}
.overbox.preview > .wrap > ul > li > span {
	list-style-type: none;
}
.overbox.preview > .wrap > ul > li > strong {
	font-family: Montserrat-SemiBold, Arial, sans-serif;
	padding-left: 10px;
}

.overbox.preview > .wrap > .codeJson {
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - 60px);
	max-width: calc(100% - 60px);
	margin: 0 15px 15px 15px;
	padding: 15px;
	background-color: #F0F0F0;
	border-radius: 3px;
	border: 1px solid #EEEEEE;
	overflow-wrap: break-word;
	word-break: break-word;
	word-wrap: break-word;
	font-size: 10pt;
}

.overbox.preview > .wrap > .codeJson {
	overflow-x: hidden;
	overflow-y: auto;
	width: calc(100% - 60px);
	max-width: calc(100% - 60px);
	margin: 0 15px 15px 15px;
	padding: 15px;
	background-color: #F0F0F0;
	border-radius: 3px;
	border: 1px solid #EEEEEE;
	overflow-wrap: break-word;
	word-break: break-word;
	font-size: 10pt;
	white-space: pre-wrap;
	white-space: -moz-pre-wrap;
	white-space: -o-pre-wrap;
	word-wrap: break-word;
}
